import React from 'react';

import { useNavigate, useParams } from 'react-router';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ChantierClientView } from '@travauxlib/shared/src/types/api/client/ChantierClientView';
import { ChantierPvView } from '@travauxlib/shared/src/types/api/common/ChantierPvView';
import {
  AdminEventsProperties,
  DocumentFinChantierProperties,
} from '@travauxlib/shared/src/utils/tracking';

import { DocumentFinChantier } from 'utils/tracking/DocumentFinChantierTracking';

import { useRejectPv } from '../../api/useRejectPv';

type OwnProps = {
  pv: ChantierPvView;
  chantier: ChantierClientView;
  rejectionReason: string;
};

type Props = OwnProps & {
  handleClose: () => void;
  projectUuid: string;
};

const RejectPvModal: React.FC<Props> = ({
  handleClose,
  pv,
  chantier,
  rejectionReason,
  projectUuid,
}) => {
  const { rejectPv, isLoading } = useRejectPv();
  const navigate = useNavigate();

  const showFrozenSentence = !!chantier.montantGele;

  return (
    <FeedbackMessages
      isOpen
      title="Refuser le document"
      variant="warning"
      validateAction={{
        label: 'Refuser le document',
        onClick: async () => {
          await rejectPv({
            pvUuid: pv.uuid,
            pvType: pv.pvType,
            chantierUuid: pv.chantierUuid,
            rejectionReason,
          });

          DocumentFinChantier.onDeclined({
            [AdminEventsProperties.ProjetUUID]: projectUuid,
            [DocumentFinChantierProperties.EndOfChantierDocumentType]: pv.pvType,
          });

          handleClose();
          navigate(-1);
        },
        disabled: isLoading,
      }}
      cancelAction={{
        label: 'Annuler',
        onClick: handleClose,
      }}
      message={
        <div className="font-normal text-b2 text-gray-600">
          Nous allons transmettre à l’entreprise le motif de votre refus et celle-ci devra vous
          soumettre un nouveau document pour validation.
          {showFrozenSentence && (
            <> Les fonds resteront gelés tant que vous n’avez pas validé le document.</>
          )}
        </div>
      }
    />
  );
};

export const useOpenRejectPvModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();
  const { uuid } = useParams<{ uuid: string }>();

  return (props: OwnProps) =>
    openModal(
      (modalProps: Omit<Props, 'projectUuid'>) => (
        <RejectPvModal {...modalProps} projectUuid={uuid!} />
      ),
      {
        ...props,
        size: 'md',
      },
    );
};
