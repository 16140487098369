import { useQuery } from '@tanstack/react-query';

import { ClientCustomerSatisfactionView } from '@travauxlib/shared/src/types/api/client/ClientCustomerSatisfactionView';
import { request } from '@travauxlib/shared/src/utils/request';

import { CLIENT_CUSTOMER_SATISFACTION_KEY } from './useCreateClientCustomerSatisfaction';

export const useGetClientCustomerSatisfaction = (
  projectUuid: string,
): { isLoading: boolean; clientCustomerSatisfaction: ClientCustomerSatisfactionView[] } => {
  const { isLoading, data } = useQuery<ClientCustomerSatisfactionView[]>({
    queryKey: [CLIENT_CUSTOMER_SATISFACTION_KEY],
    queryFn: () =>
      request(`${APP_CONFIG.apiURL}/client/customer-satisfaction/project/${projectUuid}`),
  });

  return { isLoading, clientCustomerSatisfaction: data || [] };
};
