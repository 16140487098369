import React from 'react';

import { useNavigate } from 'react-router';

import CheckSymbol from '@travauxlib/shared/src/components/DesignSystem/assets/CheckSymbolFilled.svg?react';
import { ListItem } from '@travauxlib/shared/src/components/DesignSystem/components/ListItem';
import {
  ModalContent,
  useOpenModal,
} from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ProjectView } from '@travauxlib/shared/src/types/api/client/ProjectView';

import { EtapeProjet } from 'features/EspaceClient3/features/Etapes/types';
import { etapeConfigs } from 'features/EspaceClient3/features/Etapes/utils/constants';

type OwnProps = {
  currentProjectUuid: string;
  projects: ProjectView[];
};

type Props = OwnProps & {
  handleClose: () => void;
};

export const SwitchProjectModal: React.FC<Props> = ({ currentProjectUuid, projects }) => {
  const navigate = useNavigate();

  return (
    <ModalContent>
      {projects.map(project => (
        <ListItem
          className="last:border-0"
          key={project.uuid}
          onChange={() => navigate(`/projets/${project.uuid}`)}
          state="filled"
          left={{
            type: 'iconBackground',
            icon: etapeConfigs[project.etape || EtapeProjet.Etude].icon,
            variant: 'beige',
          }}
          middle={{
            title: project.title,
            label: project.typeTravaux,
          }}
          right={
            project.uuid === currentProjectUuid
              ? { type: 'icon', icon: <CheckSymbol /> }
              : undefined
          }
        />
      ))}
    </ModalContent>
  );
};

export const useOpenSwitchProjectModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();

  return (props: OwnProps) =>
    openModal(SwitchProjectModal, {
      title: 'Changer de projet',
      closable: true,
      ...props,
    });
};
