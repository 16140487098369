import React from 'react';

import capitalize from 'lodash/capitalize';
import { useParams, useLocation } from 'react-router';

import { useDownloadAsZip } from '@travauxlib/shared/src/api/useDownloadAsZip';
import Document from '@travauxlib/shared/src/components/DesignSystem/assets/Document.svg?react';
import Download from '@travauxlib/shared/src/components/DesignSystem/assets/DownloadFilled.svg?react';
import Eye from '@travauxlib/shared/src/components/DesignSystem/assets/EyeFilled.svg?react';
import { Breadcrumb } from '@travauxlib/shared/src/components/DesignSystem/components/Breadcrumb';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { ListItem } from '@travauxlib/shared/src/components/DesignSystem/components/ListItem';
import { PvDocumentName } from '@travauxlib/shared/src/features/Chantiers/utils/PvDocumentName';
import { ChantierClientView } from '@travauxlib/shared/src/types/api/client/ChantierClientView';
import { ChantierPvView } from '@travauxlib/shared/src/types/api/common/ChantierPvView';
import { slugify } from '@travauxlib/shared/src/utils/slugify';

import { useProject } from 'features/EspaceClient3/api/useProject';

import { useOpenConsultPvModal } from './ConsultPvModal';
import { ValidatePvForm } from './ValidatePvForm';

export const PvComponent: React.FC<{ pv: ChantierPvView; chantier: ChantierClientView }> = ({
  pv,
  chantier,
}) => {
  const location = useLocation();

  const documentName = capitalize(PvDocumentName[pv.pvType]);
  const { downloadAsZip, isLoading } = useDownloadAsZip();
  const openConsultPvModal = useOpenConsultPvModal();

  return (
    <div>
      <Breadcrumb
        crumbs={[
          {
            name: 'Documents',
            url: '../..',
          },
          {
            name: documentName,
            url: location.pathname,
          },
        ]}
      />

      <div className="text-h5 py-sm mx-md sm-desktop:mx-0 font-bold">{documentName}</div>
      <div className="mb-md mx-md sm-desktop:mx-0">
        <ListItem
          className="!rounded-md active:!ring-0"
          shouldTruncate={false}
          left={{ type: 'iconBackground', icon: <Document />, size: 'lg', variant: 'beige' }}
          middle={{
            title: 'Procès-verbal',
          }}
          right={{
            type: 'jsx',
            children: (
              <>
                <IconButton
                  disabled={isLoading}
                  onClick={() =>
                    downloadAsZip({
                      zipName: slugify(documentName),
                      urls: pv.documents.map(d => d.downloadUrl),
                    })
                  }
                >
                  <Download />
                </IconButton>
                <IconButton
                  dataTestId="consult"
                  onClick={() =>
                    openConsultPvModal({
                      pv,
                      title: `Procès-verbal de ${documentName.toLowerCase()}`,
                    })
                  }
                >
                  <Eye />
                </IconButton>
              </>
            ),
          }}
        >
          <div className="flex justify-between gap-xxs text-b2 -mt-xxs">
            <div className="text-neutral-600">Contient {pv.documents.length} document(s)</div>
          </div>
        </ListItem>
      </div>

      {pv.isPending && <ValidatePvForm pv={pv} chantier={chantier} />}
    </div>
  );
};

export const Pv: React.FC = () => {
  const { uuid, proCompanyUuid, pvType } = useParams<{
    uuid: string;
    proCompanyUuid: string;
    pvType: string;
  }>();
  const { project } = useProject(uuid!);

  const chantier = project?.chantiers.find(c => c.proCompanyUuid === proCompanyUuid);
  const pv = Object.values(chantier?.pv || {}).find(pv => pv.pvType === pvType);

  if (!chantier || !pv) {
    return null;
  }
  return <PvComponent pv={pv} chantier={chantier} />;
};
