import React from 'react';

import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router';

import { FeedbackMessages } from '@travauxlib/shared/src/components/DesignSystem/components/FeedbackMessages';
import { useOpenModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { PvDocumentName } from '@travauxlib/shared/src/features/Chantiers/utils/PvDocumentName';
import { ChantierClientView } from '@travauxlib/shared/src/types/api/client/ChantierClientView';
import {
  ChantierPvView,
  ChantierPvType,
} from '@travauxlib/shared/src/types/api/common/ChantierPvView';
import {
  AdminEventsProperties,
  PvLeveeReservesProperties,
  PvReceptionProperties,
} from '@travauxlib/shared/src/utils/tracking';

import { PvLeveeReserves } from 'utils/tracking/PvLeveeReservesTracking';
import { PvReception } from 'utils/tracking/PvReceptionTracking';

import { useValidatePv } from '../../api/useValidatePv';

type OwnProps = {
  pv: ChantierPvView;
  chantier: ChantierClientView;
  hasReserves: boolean;
  dateSignature: string;
};

type Props = OwnProps & {
  handleClose: () => void;
  projectUuid: string;
};

const frozenSentence = (
  { montantGeleIfReserves, montantGele }: ChantierClientView,
  { pvType, hasReserves }: { pvType: ChantierPvType; hasReserves?: boolean },
): React.ReactElement => {
  const documentName = PvDocumentName[pvType];
  if (
    !hasReserves ||
    pvType === ChantierPvType.LeveeReserves ||
    !montantGeleIfReserves ||
    montantGeleIfReserves === montantGele
  ) {
    return (
      <div>
        Après avoir validé le procès-verbal de {documentName}, nous allons automatiquement libérer
        l'intégralité des <EURCurrency amount={montantGele} /> actuellement gelés sur votre compte
        séquestre.
      </div>
    );
  }

  const remaining = montantGele - montantGeleIfReserves;

  return (
    <div>
      Après avoir validé le procès-verbal de {documentName}, nous allons automatiquement libérer une
      partie des <EURCurrency amount={montantGele} /> actuellement gelés sur votre compte séquestre.{' '}
      <EURCurrency amount={montantGeleIfReserves} /> seront libéres immédiatement et{' '}
      <EURCurrency amount={remaining} /> seront conservés jusqu'à la levée des réserves.
    </div>
  );
};

const ValidatePvModal: React.FC<Props> = ({
  handleClose,
  pv,
  chantier,
  hasReserves,
  dateSignature,
  projectUuid,
}) => {
  const { validatePv, isLoading } = useValidatePv();
  const navigate = useNavigate();

  const showFrozenSentence = !!chantier.montantGele;

  return (
    <FeedbackMessages
      isOpen
      title="Valider le document"
      variant="warning"
      validateAction={{
        label: 'Valider le document',
        onClick: async () => {
          await validatePv({
            pvUuid: pv.uuid,
            pvType: pv.pvType,
            chantierUuid: pv.chantierUuid,
            hasReserves,
            dateSignature,
          });

          if (pv.pvType === ChantierPvType.LeveeReserves) {
            const { pv: pvs } = chantier;
            const { receptionChantier } = pvs;
            const daysSinceReception = dayjs(dateSignature).diff(
              receptionChantier?.dateSignature,
              'day',
            );

            PvLeveeReserves.onValidated({
              [AdminEventsProperties.ProjetUUID]: projectUuid,
              [PvLeveeReservesProperties.NbOfDaysSinceReception]: daysSinceReception,
            });
          } else {
            PvReception.onValidated({
              [AdminEventsProperties.ProjetUUID]: projectUuid,
              [PvReceptionProperties.PvReceptionAvecReserves]: hasReserves,
            });
          }
          toast.success('Document(s) validé(s) avec succès', {
            actionLabel: showFrozenSentence ? 'Les fonds ont été libérés avec succès' : undefined,
          });

          handleClose();
          navigate(-1);
        },
        disabled: isLoading,
      }}
      cancelAction={{
        label: 'Annuler',
        onClick: handleClose,
      }}
      message={
        <div className="font-normal text-b2 text-gray-600">
          {frozenSentence(chantier, { hasReserves, pvType: pv.pvType })}
        </div>
      }
    />
  );
};

export const useOpenValidatePvModal = (): ((props: OwnProps) => void) => {
  const openModal = useOpenModal();
  const { uuid } = useParams<{ uuid: string }>();

  return (props: OwnProps) =>
    openModal(
      (modalProps: Omit<Props, 'projectUuid'>) => (
        <ValidatePvModal {...modalProps} projectUuid={uuid!} />
      ),
      {
        ...props,
        size: 'md',
        closable: true,
      },
    );
};
