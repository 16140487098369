import { useEffect } from 'react';

import { useSearchParams } from 'react-router';

import { ProjectView } from '@travauxlib/shared/src/types/api/client/ProjectView';

import { useGetAccount } from 'api/useGetAccount';
import { openCalendly } from 'utils/calendly';

export const useOpenCalendlyWatcher = (project?: ProjectView): void => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { account } = useGetAccount();
  const shouldOpenCalendly = searchParams.get('openCalendly') !== null;

  return useEffect(() => {
    if (project?.calendlyUrl && account && shouldOpenCalendly) {
      openCalendly(account, project.calendlyUrl, 'ec3-action');
      setSearchParams('', { replace: true });
    }
  }, [shouldOpenCalendly, project, account]);
};
