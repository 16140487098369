import _sum from 'lodash/sumBy';

import Chat from '@travauxlib/shared/src/components/DesignSystem/assets/Chat.svg?react';
import Document from '@travauxlib/shared/src/components/DesignSystem/assets/Document.svg?react';
import Euro from '@travauxlib/shared/src/components/DesignSystem/assets/EuroFilled.svg?react';
import HandyMan from '@travauxlib/shared/src/components/DesignSystem/assets/HandyMan.svg?react';
import HomeFilled from '@travauxlib/shared/src/components/DesignSystem/assets/HomeFilled.svg?react';
import { DevisStatuses } from '@travauxlib/shared/src/types';
import { BadgeCountView } from '@travauxlib/shared/src/types/api/client/BadgeCountView';
import { ChantierClientView } from '@travauxlib/shared/src/types/api/client/ChantierClientView';

export type NavigationItem = {
  Icon: React.ComponentType<{ className: string }>;
  label: string;
  path: string;
  isActive?: boolean;
  badgeCount: number;
};

export const getNavigationItems = (
  projectUuid: string,
  pathname: string,
  badges: BadgeCountView,
): NavigationItem[] => [
  {
    Icon: HomeFilled,
    label: 'Accueil',
    path: `/projets/${projectUuid}`,
    isActive: pathname === `/projets/${projectUuid}`,
    badgeCount: badges.actions,
  },
  {
    Icon: Euro,
    label: 'Paiements',
    path: 'paiements',
    isActive: pathname.startsWith(`/projets/${projectUuid}/paiements`),
    badgeCount: _sum(Object.values(badges.payments)),
  },
  {
    Icon: HandyMan,
    label: 'Chantier',
    path: 'chantiers',
    isActive: pathname.startsWith(`/projets/${projectUuid}/chantiers`),
    badgeCount: badges.chantiers,
  },
  {
    Icon: Document,
    label: 'Documents',
    path: 'documents',
    isActive: pathname.startsWith(`/projets/${projectUuid}/documents`),
    badgeCount: _sum(Object.values(badges.companies)),
  },
  {
    Icon: Chat,
    label: 'Guides',
    path: 'guides',
    isActive: pathname.startsWith(`/projets/${projectUuid}/guides`),
    badgeCount: 0,
  },
];

export const routesWithoutHeaderTapBar = [
  'suivi-chantier-recap/',
  'suivi-chantier/',
  'factures/',
  'fournitures',
];
export const routesWithoutLayout = ['suivi-chantier/', 'fournitures'];

export const formatIBANForDisplay = (chantier: ChantierClientView): string =>
  [...chantier.iban.replaceAll(' ', '')].reduce(
    (acc, c, idx) => `${acc}${idx % 4 === 0 ? ' ' : ''}${c}`,
    '',
  );

export const routesWithoutTapBar = ['factures', 'devis', 'guides/'];

export const StatusDisplay: { [key in DevisStatuses]: string } = {
  [DevisStatuses.signed]: 'Signé',
  [DevisStatuses.sent]: 'Reçu',
  [DevisStatuses.rejected]: 'Refusé',
  [DevisStatuses.draft]: 'Brouillon',
  [DevisStatuses.canceled]: 'Annulé',
  [DevisStatuses.to_be_revised]: 'À réviser',
  [DevisStatuses.rejected_hemea]: 'Refusé',
  [DevisStatuses.to_be_revised_hemea]: 'À réviser',
  [DevisStatuses.sent_hemea]: 'Reçu',
};

export const routeCustomerSatisfaction = 'customer-satisfaction';
export const routePaiements = 'paiements';
