import { useQuery } from '@tanstack/react-query';

import { ProjectView } from '@travauxlib/shared/src/types/api/client/ProjectView';
import { request } from '@travauxlib/shared/src/utils/request';

export const PROJECTS_KEY = 'PROJECTS';

export const useProjects = (): { isLoading: boolean; projects: ProjectView[] } => {
  const { isLoading, data } = useQuery<{ data: ProjectView[] }>({
    queryKey: [PROJECTS_KEY],
    queryFn: () => request(`${APP_CONFIG.apiURL}/client/project`),
  });

  return { isLoading, projects: data?.data || [] };
};
