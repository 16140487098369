import React from 'react';

import { SimpleModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { Tabs } from '@travauxlib/shared/src/components/DesignSystem/components/Tabs';
import { useFeatureFlag } from '@travauxlib/shared/src/hooks/useFeatureFlag';
import { Prestations } from '@travauxlib/shared/src/types';

import { PrimeTab } from './PrimeTab';
import { RemiseTab } from './RemiseTab';

type Props = {
  prestations: Omit<Prestations, 'title' | 'token' | 'lots'>;
  onChange: (prestations: Partial<Prestations>) => void;
  isOpen: boolean;
  handleClose: () => void;
};

export const RemisePrimesModal: React.FC<Props> = ({
  prestations,
  onChange,
  isOpen,
  handleClose,
}) => {
  const showPrime = useFeatureFlag('prime');

  const tabs = [
    { label: 'Remise commerciale', to: '?remise', isActive: () => !showPrime },
    { label: 'Prime', to: '?prime', isActive: () => showPrime },
  ];

  const {
    prixTotalHT,
    prixTotalTTC,
    prixTotalTTCAvantRemise,
    prixTotalHTAvantRemise,
    prixTotalTTCAvantPrimes,
    remises,
    primes,
  } = prestations;

  return (
    <SimpleModal title="Ajouter une remise ou une prime" isOpen={isOpen} handleClose={handleClose}>
      <>
        <Tabs className="mb-md" items={tabs} />
        {showPrime ? (
          <PrimeTab
            onChange={primes => onChange({ primes })}
            primes={primes}
            totalTTC={prixTotalTTC}
            totalTTCAvantPrimes={prixTotalTTCAvantPrimes}
          />
        ) : (
          <RemiseTab
            onChange={remises => onChange({ remises })}
            remises={remises}
            totalHT={prixTotalHT}
            totalHTAvantRemise={prixTotalHTAvantRemise}
            totalTTCAvantPrimes={prixTotalTTCAvantPrimes}
            totalTTCAvantRemise={prixTotalTTCAvantRemise!}
          />
        )}
      </>
    </SimpleModal>
  );
};
