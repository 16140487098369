import React from 'react';

import { ChantierClientView } from '@travauxlib/shared/src/types/api/client/ChantierClientView';

import { PaiementCard } from './PaiementCard';
import { ProvisionCard } from './ProvisionCard';

export const SuiviFinancier: React.FC<{ chantier: ChantierClientView }> = ({ chantier }) => (
  <div className="mx-md sm-desktop:mx-0">
    <div className="flex items-center justify-between">
      <div className="text-h5 font-bold my-sm">Suivi financier</div>
    </div>
    <div className="flex flex-col gap-xs">
      <ProvisionCard chantier={chantier} />
      <PaiementCard chantier={chantier} />
    </div>
  </div>
);
