import { useQuery } from '@tanstack/react-query';

import { EnterpriseChipView } from '@travauxlib/shared/src/types/api/client/EnterpriseChipView';
import { request } from '@travauxlib/shared/src/utils/request';

const COMPANY_CHIPS_KEY = 'PROJECT_COMPANY_CHIPS';

export const useCompanyChip = (
  projectUuid: string,
  entityType: string,
): { isLoading: boolean; data: EnterpriseChipView[] } => {
  const { isLoading, data } = useQuery<EnterpriseChipView[]>({
    queryKey: [COMPANY_CHIPS_KEY, projectUuid, entityType],
    queryFn: () =>
      request(`${APP_CONFIG.apiURL}/client/project/${projectUuid}/company-chips/${entityType}`),
  });

  return { isLoading, data: data || [] };
};
