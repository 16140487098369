import { useQuery } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ProjectView } from '@travauxlib/shared/src/types/api/client/ProjectView';
import { request } from '@travauxlib/shared/src/utils/request';

import { PROJECTS_KEY } from 'features/EspaceClient3/api/useProjects';

export const useProject = (uuid: string): { project?: ProjectView; isLoading: boolean } => {
  const { data, isLoading } = useQuery<ProjectView>({
    queryKey: [PROJECTS_KEY, uuid],
    queryFn: async () => {
      try {
        return await request(`${APP_CONFIG.apiURL}/client/project/${uuid}`);
      } catch (err) {
        toast.error('Une erreur est survenue pendant le chargement de votre projet');
        throw err;
      }
    },
  });

  return {
    project: data,
    isLoading,
  };
};
