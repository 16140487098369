import { useQuery } from '@tanstack/react-query';

import { Meeting } from '@travauxlib/shared/src/types';
import { PersonMeetingView } from '@travauxlib/shared/src/types/api/client/PersonMeetingView';
import { request } from '@travauxlib/shared/src/utils/request';

type ApiReturn = { meetings?: Meeting[]; persons?: { [k: string]: PersonMeetingView } };

export const useProjectMeetings = (projectToken: string): { isLoading: boolean } & ApiReturn => {
  const { isLoading, data } = useQuery<ApiReturn>({
    queryKey: ['PROJECT_MEETINGS', projectToken],
    queryFn: () => request(`${APP_CONFIG.apiURL}/client/project/${projectToken}/meetings`),
  });

  return { meetings: data?.meetings, persons: data?.persons, isLoading };
};
